import {
  computed,
  ref,
} from 'vue'

export type Breakpoint =
  | 'p-sm'
  | 'p-md'
  | 't-sm'
  | 't-md'
  | 'd-xs'
  | 'd-sm'
  | 'd-md'
  | 'd-lg'

const max = {
  'p-sm': 480 - 1,
  'p-md': 640 - 1,
  't-sm': 768 - 1,
  't-md': 1024 - 1,
  'd-xs': 1280 - 1,
  'd-sm': 1440 - 1,
  'd-md': 1920 - 1,
  'd-lg': Infinity,
}

class BreakpointValue {
  private readonly _name: Breakpoint

  constructor (name: Breakpoint) {
    this._name = name
  }

  is (breakpoint: Breakpoint): boolean {
    return this._name === breakpoint
  }

  ge (than: Breakpoint) {
    return max[this._name] >= max[than]
  }

  lt (than: Breakpoint) {
    return max[this._name] < max[than]
  }
}

const calculateBreakpoint = (): BreakpointValue => {
  if (window.innerWidth <= max['p-sm']) return new BreakpointValue('p-sm')
  if (window.innerWidth <= max['p-md']) return new BreakpointValue('p-md')
  if (window.innerWidth <= max['t-sm']) return new BreakpointValue('t-sm')
  if (window.innerWidth <= max['t-md']) return new BreakpointValue('t-md')
  if (window.innerWidth <= max['d-xs']) return new BreakpointValue('d-xs')
  if (window.innerWidth <= max['d-sm']) return new BreakpointValue('d-sm')
  if (window.innerWidth <= max['d-md']) return new BreakpointValue('d-md')

  return new BreakpointValue('d-lg')
}

const breakpoint = ref<BreakpointValue>(new BreakpointValue('p-sm'))
const update = () => breakpoint.value = calculateBreakpoint()

if (typeof window !== 'undefined') {
  update()
  window.addEventListener('resize', update)
}

export const useBreakpoint = () => computed(() => breakpoint.value)
