<script lang="ts" setup>
import {
  DropdownMenuContent,
  DropdownMenuPortal,
  useForwardPropsEmits,
  type DropdownMenuContentEmits,
  type DropdownMenuContentProps,
} from 'radix-vue'
import { useAttrs } from 'vue'

const props = withDefaults(defineProps<DropdownMenuContentProps>(), {
  align: 'start',
})
const emits = defineEmits<DropdownMenuContentEmits>()
const forwarded = useForwardPropsEmits(props, emits)
const attrs = useAttrs()
</script>
<template>
    <DropdownMenuPortal>
        <DropdownMenuContent
            class="dropdown-content tw-max-w-96 tw-overflow-hidden tw-rounded-lg  tw-bg-white tw-py-[15px] tw-px-2.5 tw-shadow tw-z-[1000] tw-animate-dropdownIn"
            v-bind="{ ...forwarded, class: attrs.class }"
        >
            <slot />
        </DropdownMenuContent>
    </DropdownMenuPortal>
</template>
<style lang="postcss" scoped>
:deep(.dropdown-content) {
  min-width: var(--radix-dropdown-menu-trigger-width);
}
</style>
