<script setup lang="ts">

</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
    >
        <circle
            cx="12"
            cy="12.5"
            r="9"
            stroke="currentColor"
            stroke-width="2"
        />
        <path
            d="M9 11.5L12 8.5L15 11.5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="11"
            y="8.5"
            width="2"
            height="9"
            rx="1"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped lang="scss">

</style>
