import LayoutAuthorized from '@/layouts/LayoutAuthorized.vue'

import {
  createRouter,
  createWebHistory,
} from 'vue-router'

export enum AUTHENTICATION {
  GUEST = 'guest',
  USER = 'user'
}

export enum ROUTES {
  LOGIN = 'login',
  LOGIN_GOOGLE = 'login-google',
  REGISTER = 'register',
  PASSWORD_RESET = 'password-reset',
  PASSWORD_RESET_REQUEST = 'password-reset-request',
  REPORT = 'report',
  AI_FINDER_REQUEST = 'ai-finder-request',
  REPORT_LIST = 'report-list',
  REPORT_LIST_FULL = 'report-list-full',
  REPORT_LIST_ASIN = 'report-list-asin',
  REPORT_LIST_UNRESTRICTED = 'report-list-unrestricted',
  REPORT_PRODUCT = 'report-product',
  SINGLE_ASIN_REQUEST = 'single-asin-request',
  BROWSE = 'browse',
  TOP_10_PICKS = 'top-10-picks',
  PRODUCT_TRACKER = 'product-tracker',
  TRENDING_PRODUCTS = 'trending-products',
  PRICING = 'pricing',
  SUPPORT = 'support',
  SETTINGS = 'settings',
  VERIFY_EMAIL = 'verify-email',
  COPILOT = 'copilot',
  EBAY_CONNECT_SUCCESS = 'ebay-connect-success',
  EBAY_CONNECT_FAILURE = 'ebay-connect-failure',
  HTTP_404 = '404'
}

declare module 'vue-router' { interface RouteMeta { authentication?: `${AUTHENTICATION}` } }

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      meta: { authentication: AUTHENTICATION.USER },
      component: LayoutAuthorized,
      redirect: { name: ROUTES.BROWSE },
      children: [
        {
          name: ROUTES.REPORT,
          path: '/report/:uuid',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageReport.vue'),
        },
        {
          name: ROUTES.REPORT_PRODUCT,
          path: '/report/:uuid/product/:id',
          component: () => import('@/pages/PageReportProduct.vue'),
        },
        {
          name: ROUTES.BROWSE,
          path: '/browse',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageBrowse.vue'),
        },
        {
          name: ROUTES.TOP_10_PICKS,
          path: '/top-10-picks',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageTop10Picks.vue'),
        },
        {
          name: ROUTES.PRODUCT_TRACKER,
          path: '/product-tracker',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageProductTracker.vue'),
        },

        {
          name: ROUTES.SINGLE_ASIN_REQUEST,
          path: '/sales-roi-analyzer',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageSingleAsinRequest.vue'),
        },
        {
          name: ROUTES.TRENDING_PRODUCTS,
          path: '/trending-products',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageTrendingProducts.vue'),
        },
        {
          name: ROUTES.PRICING,
          path: '/pricing',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PagePricing.vue'),
        },
        {
          name: ROUTES.SUPPORT,
          path: '/support',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageSupport.vue'),
        },
        {
          name: ROUTES.SETTINGS,
          path: '/settings',
          meta: { authentication: AUTHENTICATION.USER },
          component: () => import('@/pages/PageSettings.vue'),
        },
      ],
    },
    {
      name: ROUTES.LOGIN,
      path: '/login',
      meta: { authentication: AUTHENTICATION.GUEST },
      component: () => import('@/pages/PageLogin.vue'),
    },
    {
      name: ROUTES.LOGIN_GOOGLE,
      path: '/google/login/callback/',
      meta: { authentication: AUTHENTICATION.GUEST },
      component: () => import('@/pages/PageLoginGoogle.vue'),
    },
    {
      name: ROUTES.REGISTER,
      path: '/register',
      meta: { authentication: AUTHENTICATION.GUEST },
      component: () => import('@/pages/PageRegister.vue'),
    },
    {
      name: ROUTES.PASSWORD_RESET,
      path: '/reset_password/',
      meta: { authentication: AUTHENTICATION.GUEST },
      component: () => import('@/pages/PagePasswordReset.vue'),
    },
    {
      name: ROUTES.PASSWORD_RESET_REQUEST,
      path: '/password-reset-request',
      meta: { authentication: AUTHENTICATION.GUEST },
      component: () => import('@/pages/PagePasswordResetRequest.vue'),
    },
    {
      name: ROUTES.VERIFY_EMAIL,
      path: '/verify_email',
      meta: { authentication: AUTHENTICATION.GUEST },
      component: () => import('@/pages/PageVerifyEmail.vue'),
    },
    {
      path: '/verify_email/',
      meta: { authentication: AUTHENTICATION.GUEST },
      redirect: { name: ROUTES.VERIFY_EMAIL },
    },
    {
      name: ROUTES.COPILOT,
      path: '/copilot/',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageCopilot.vue'),
    },
    {
      name: ROUTES.EBAY_CONNECT_SUCCESS,
      path: '/marketplaces/ebay/new/success/',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageEbaySuccess.vue'),
    },
    {
      name: ROUTES.EBAY_CONNECT_FAILURE,
      path: '/marketplaces/ebay/new/failure/',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageEbayFailure.vue'),
    },
    {
      name: ROUTES.HTTP_404,
      path: '/404',
      component: () => import('@/pages/Page404.vue'),
    },
    {
      name: 'unknown-page',
      path: '/:pathMatch(.*)*',
      redirect: { name: '404' },
    },
  ],
})

export default router
