<script lang="ts" setup>
import {
  DropdownMenuItem,
  useForwardPropsEmits,
  type DropdownMenuItemEmits,
  type DropdownMenuItemProps,
} from 'radix-vue'

// const emit = defineEmits<{
//   (e: 'select'): void
// }>()

const props = defineProps<DropdownMenuItemProps>()
const emits = defineEmits<DropdownMenuItemEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>
<template>
    <DropdownMenuItem class="menu-item" v-bind="forwarded">
        <slot />
    </DropdownMenuItem>
</template>
<style lang="postcss" scoped>
.menu-item {
  @apply tw-flex tw-min-h-[40px] tw-cursor-pointer tw-select-none tw-items-center tw-gap-2.5 tw-px-[15px] tw-pb-[10px] tw-pt-[8px] tw-leading-none tw-rounded-lg tw-outline-none;
  @apply data-[highlighted]:tw-bg-[#e6f0fe] data-[highlighted]:tw-text-finder active:data-[highlighted]:tw-bg-black/10;
}
</style>
