<script setup lang="ts">

</script>

<template>
    <div class="pl-[1px] py-[1px] pr-[3px]">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path d="M8.4765 7.19L6.6665 3L4.8565 7.19L0.666504 9L4.8565 10.81L6.6665 15L8.4765 10.81L12.6665 9L8.4765 7.19Z" fill="currentColor" />
            <path d="M13.6665 6L14.5765 3.91L16.6665 3L14.5765 2.09L13.6665 0L12.7565 2.09L10.6665 3L12.7565 3.91L13.6665 6Z" fill="currentColor" />
            <path d="M13.6665 12L12.7565 14.09L10.6665 15L12.7565 15.91L13.6665 18L14.5765 15.91L16.6665 15L14.5765 14.09L13.6665 12Z" fill="currentColor" />
        </svg>
    </div>
</template>

<style scoped lang="scss">

</style>
