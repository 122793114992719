<script setup lang="ts">

</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
    >
        <path d="M2 5.5C2 4.39543 2.89543 3.5 4 3.5H20C21.1046 3.5 22 4.39543 22 5.5V15.5C22 16.6046 21.1046 17.5 20 17.5H13L7 21.5V17.5H4C2.89543 17.5 2 16.6046 2 15.5V5.5Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
        <circle cx="12" cy="10.5" r="2" fill="currentColor" />
        <circle cx="7" cy="10.5" r="2" fill="currentColor" />
        <circle cx="17" cy="10.5" r="2" fill="currentColor" />
    </svg>
</template>

<style scoped lang="scss">

</style>
