<script setup lang="ts">

</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
    >
        <circle
            cx="12"
            cy="8.5"
            r="5"
            stroke="currentColor"
            stroke-width="2"
        />
        <path d="M20 21.5C20 17.0817 16.4183 13.5 12 13.5C7.58172 13.5 4 17.0817 4 21.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
    </svg>
</template>

<style scoped lang="scss">

</style>
