<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
    >
        <path d="M2.3335 5L10.3335 9V19L2.3335 15V5Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
        <path d="M2.3335 5L10.3335 1L18.3335 5L10.3335 9L2.3335 5Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
        <path d="M10.3335 9L18.3335 5V15L10.3335 19V9Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
    </svg>
</template>

<style scoped lang="scss">

</style>
