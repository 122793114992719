<script setup lang="ts">

</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <rect
            x="2"
            y="2"
            width="6"
            height="6"
            rx="2"
            stroke="currentColor"
            stroke-width="2"
        />
        <rect
            x="2"
            y="12"
            width="6"
            height="6"
            rx="2"
            stroke="currentColor"
            stroke-width="2"
        />
        <rect
            x="12"
            y="2"
            width="6"
            height="6"
            rx="2"
            stroke="currentColor"
            stroke-width="2"
        />
        <rect
            x="12"
            y="12"
            width="6"
            height="6"
            rx="2"
            stroke="currentColor"
            stroke-width="2"
        />
    </svg>
</template>

<style scoped lang="scss">

</style>
