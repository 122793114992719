import type { Maybe } from './scaffolding'

import {
  isShape,
  isString,
} from '@/predicates'

import {
  HTTP_BAD_REQUEST,
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
} from './statuses.ts'

export class FetchError extends Error {}

export type HttpErrorData = {
  message: string;
  code: string;
  errors?: Record<string, string>;
}

export class HttpError extends Error {
  private readonly _data: Maybe<HttpErrorData>
  private readonly _status: number

  constructor (message: string, status: number, data: Maybe<HttpErrorData> = undefined) {
    super(message)
    this._data = data
    this._status = status
  }

  get data (): Maybe<HttpErrorData> { return this._data }
  get status () { return this._status }
}

export class BadRequestHttpError extends HttpError {
  constructor (message: string, data: Maybe<HttpErrorData> = undefined) {
    super(message, HTTP_BAD_REQUEST, data)
  }
}

export class UnauthorizedHttpError extends HttpError {
  constructor (message: string, data: Maybe<HttpErrorData> = undefined) {
    super(message, HTTP_UNAUTHORIZED, data)
  }
}

export class ForbiddenHttpError extends HttpError {
  constructor (message: string, data: Maybe<HttpErrorData> = undefined) {
    super(message, HTTP_FORBIDDEN, data)
  }
}

export class NotFoundHttpError extends HttpError {
  constructor (message: string, data: Maybe<HttpErrorData> = undefined) {
    super(message, HTTP_NOT_FOUND, data)
  }
}

export class ServerError extends HttpError {}

const addStack = <E extends Error>(error: E, stack: string): E => {
  error.stack += '\n' + stack
  return error
}

export const createErrorAugmenter = () => {
  const stack = new Error().stack ?? ''

  return (e: unknown): unknown => {
    if (e instanceof Error) {
      return addStack(e, stack)
    }

    return e
  }
}

export async function fromResponse (url: string, response: Response) {
  let data: Maybe<HttpErrorData> = undefined
  try {
    data = await response.json()
    if (!isShape({
      code: [isString, true],
      message: [isString, true],
    })(data)) {
      data = undefined
    }
  } catch (e: unknown) { /* empty */ }

  const endpoint = response.url === url ? url : `${response.url} (${url})`
  const status = `${response.status} (${response.statusText})`
  const message = `Request to ${endpoint} failed with status ${status}`

  switch (response.status) {
    case HTTP_BAD_REQUEST:
      return new BadRequestHttpError(message, data)
    case HTTP_UNAUTHORIZED:
      return new UnauthorizedHttpError(message, data)
    case HTTP_FORBIDDEN:
      return new ForbiddenHttpError(message, data)
    case HTTP_NOT_FOUND:
      return new NotFoundHttpError(message, data)
    default:
      return response.status >= 500 && response.status <= 599
        ? new ServerError(message, response.status, data)
        : new HttpError(message, response.status, data)
  }
}
