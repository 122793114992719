import 'vuetify/styles'
import '@coreui/chartjs/dist/css/coreui-chartjs.css'
import '@mdi/font/css/materialdesignicons.css'
import './stylesheets/app.scss'

import type { RouteLocationNormalized } from 'vue-router'

import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'

import useMainStore from '@/store/main.ts'

import App from './App.vue'

import client from '@/api/client'
import router, { AUTHENTICATION, ROUTES } from './router'
import sentry, { setUser } from './sentry'
import vuetify from './vuetify'

import * as db from '@/db.ts'

const app = createApp(App)

app.use(createHead())
app.use(createPinia())

const store = useMainStore()

const start = async () => {
  try {
    const [jwt, user] = await Promise.all([
      db.get('jwt'),
      db.get('user'),
    ])

    client.jwt = jwt
    store.set({ jwt, user })
    setUser(user)
  } catch (e) {
    console.error(e)
  } finally {
    store.$subscribe(async (_, { jwt, user }) => {
      client.jwt = jwt
      await db.put({
        jwt: jwt ? { ...jwt } : null,
        user: user ? { ...user, createdAt: new Date(user.createdAt) } : null,
      })
      setUser(user)
    })
  }

  const guarded = (authenticated: boolean, route: RouteLocationNormalized) => {
    if (!authenticated && route.meta.authentication === AUTHENTICATION.USER) {
      return { name: ROUTES.LOGIN }
    }

    if (authenticated && route.query.copilot) {
      return { name: ROUTES.COPILOT }
    }

    if (authenticated && route.meta.authentication === AUTHENTICATION.GUEST) {
      return { name: ROUTES.BROWSE }
    }

    return null
  }

  router.beforeEach((to) => {
    const redirect = guarded(store.authenticated, to)
    if (redirect) {
      return redirect
    }
  })

  app.use(router)
  app.use(vuetify)
  app.use(sentry, router)

  app.mount('#app').$watch(() => ({
    authenticated: store.authenticated,
    route: router.currentRoute.value,
  }), ({ authenticated, route }) => {
    const redirect = guarded(authenticated, route)
    if (redirect) {
      return router.push(redirect)
    }
  })
}

start()
